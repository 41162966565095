import './src/styles/global.scss';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider
    useEnterprise
    reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
    scriptProps={{ async: true, defer: true, appendTo: 'body' }}
  >
    {element}
  </GoogleReCaptchaProvider>
);
